
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Form } from 'vee-validate';

import FormField from '@/components/forms/FormField.vue';

import { Auth } from '@/models/AuthModel';
import { AuthSchema } from '@/schemas/common.schemas';
import { Actions, Getters } from '@/store/enums/AuthEnums';
import toasts from '@/utils/toasts';

const initialValues = {} as unknown as Auth;

export default defineComponent({
  name: 'sign-in-form',
  emits: ['userLoggedIn'],
  components: {
    FormField,
    Form,
  },
  data: () => ({
    form: {} as typeof Form,
    formData: initialValues,
    loading: false,
    submitButton: {} as HTMLElement,
  }),
  mounted() {
    // setup the form reference
    this.form = this.$refs.loginForm as typeof Form;

    // setup the form reference
    this.submitButton = this.$refs.submitButton as HTMLElement;
  },
  computed: {
    ...mapGetters({
      loginErrors: Getters.GET_AUTH_ERROR,
    }),

    loginFormSchema() {
      return AuthSchema;
    },
  },
  methods: {
    ...mapActions({
      userLogin: Actions.LOGIN,
    }),

    resetForm() {
      // Reset the form validation and values
      this.form.resetForm();

      // Reset the form values
      this.formData = initialValues;
    },

    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },

    submitLoginForm(values) {
      if (this.loading) return;
      this.loading = true;

      this.resetForm();

      this.formData = values;

      this.userLogin(this.formData)
        .then(() => {
          this.loading = false;

          if (this.$route.query.ref) {
            this.$router.replace({
              name: 'view-tenant-review',
              params: { refNo: this.$route.query.ref as any },
            });
            return false;
          }

          if (this.$route.query.req) {
            this.$router.replace({
              name: 'submit-tenant-review',
              query: { ref: this.$route.query.req as any },
            });
            return false;
          }

          // this.$router.push({ name: 'dashboard' });
          window.location.replace(`${process.env.VUE_APP_BASE_URL}/dashboard`);
        })
        .catch(() => {
          this.loading = false;
          const { errors, message } = this.loginErrors;
          toasts.error(errors, message);
        });
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
  },
});
